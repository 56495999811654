<template>
  <IAmOverlay :loading="loading">
    <b-card v-if="bookingData">
      <div
        class="d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25"
        style="width: fit-content;"
      >
        <span class="font-weight-bolder d-flex-center justify-content-start divider-after">
          <b
            class="text-airline font-large-1 text-nowrap"
            :class="`${isMobileView ? 'font-medium-3' : 'font-large-1'}`"
          >
            {{ bookingData.bookingCode }}
          </b>
          <BButton
            v-b-tooltip.hover.window.top="$t('copy')"
            variant="flat-primary"
            class="p-50 rounded-circle ml-25"
            :disabled="disabledCopy"
            @click="copyText(bookingData?.bookingCode)"
          >
            <IAmIcon
              icon="copy"
              size="18"
              color="#2FA4E5"
            />
          </BButton>
        </span>
      </div>
    </b-card>
  </IAmOverlay>
</template>
<script>
import { BButton, BCard, VBTooltip } from 'bootstrap-vue'

import router from '@/router'
import IAmOverlay from '@/components/IAmOverlay.vue'

import useTrainTicketDetailHandle from './useTrainTicketDetailHandle'

export default {
  components: {
    IAmOverlay,
    BCard,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const {
      getBookingById, loading, bookingData, copyText, disabledCopy,
    } = useTrainTicketDetailHandle()

    if (router.currentRoute.params.id) {
      getBookingById(router.currentRoute.params.id)
    }
    return {
      bookingData,
      loading,
      copyText,
      disabledCopy,
    }
  },
}
</script>
<style lang="">

</style>
