import { computed, ref } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import store from '@/store'

import storeModule from '@train/trainStoreModule'

import useToast from '@useToast'

export default function useTrainTicketDetailHandle() {
  if (!store.hasModule('app-train')) {
    store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }
  const { toastError } = useToast()

  // const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

  const loading = ref(false)

  const getBookingById = bookingId => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-train/getBookingById', bookingId)
      .then(res => resolve(res.data))
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Tải chi tiết vé thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const bookingData = computed(() => store.getters['app-train/getBookingData'])

  const disabledCopy = ref(false)

  const { copy } = useClipboard()
  function copyText(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  return {
    loading,
    getBookingById,
    disabledCopy,
    copyText,
    bookingData,
  }
}
