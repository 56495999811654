var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm.bookingData ? _c('b-card', [_c('div', {
    staticClass: "d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25",
    staticStyle: {
      "width": "fit-content"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder d-flex-center justify-content-start divider-after"
  }, [_c('b', {
    staticClass: "text-airline font-large-1 text-nowrap",
    class: "".concat(_vm.isMobileView ? 'font-medium-3' : 'font-large-1')
  }, [_vm._v(" " + _vm._s(_vm.bookingData.bookingCode) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window.top",
      value: _vm.$t('copy'),
      expression: "$t('copy')",
      modifiers: {
        "hover": true,
        "window": true,
        "top": true
      }
    }],
    staticClass: "p-50 rounded-circle ml-25",
    attrs: {
      "variant": "flat-primary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": function click($event) {
        var _vm$bookingData;
        return _vm.copyText((_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.bookingCode);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "copy",
      "size": "18",
      "color": "#2FA4E5"
    }
  })], 1)], 1)])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }